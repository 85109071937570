define('require/apps/footer/footer',['require','jquery'],function(require) {
    'use strict';

    var $ = require('jquery');

    return {

        init: function() {

            var self = this;

            self.resizeRegionSelector();

            $('.region-selector--dropdown').on('click', function(event) {
                event.stopPropagation();
                self.showLocaleDropdown();
            });

            $('.region-selector--active').on('click', function(e) {
                e.preventDefault();
            });

            $('.region-selector--select select').bind('change', function() {
                var url = $(this).val();

                self.setLocale(url);
            });

            $('body').on('click', function(){
                self.hideLocaleDropdown();
            });

            $(window).resize(function() {
                self.resizeRegionSelector();
            });

        },

        resizeRegionSelector: function() {
            var dropdownWidth = $('.region-selector--wrapper').width();

            $('.region-selector--dropdown').css('width', dropdownWidth);
        },

        showLocaleDropdown: function() {
            $('.region-selector--dropdown ul').toggleClass('active');
        },

        hideLocaleDropdown: function() {
            $('.region-selector--dropdown ul').removeClass('active');
        },

        setLocale: function(url) {
            window.location = url;
            return false;
        }
    };
});

require([
    'eb/require_base_config',
    'require/apps/footer/footer'
], function(
    RequireConfig,
    Footer
) {
    'use strict';

    Footer.init();
});

define("require/apps/footer/app", function(){});

